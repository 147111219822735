import React from "react"
import Button from "app/components/Button"
import Heading from "app/components/Heading"
import { useTranslate } from "hooks/translate"
import { Locales } from "i18n"
import { useSelector } from "react-redux"
import { getAppLocale } from "state/app/selectors"
import Space from "app/components/Space"
import { StyledBottomBox } from "app/components/Box/style"

const href = {
  [Locales.NL]:
    "https://www.polestar.com/nl-be/test-drive/booking",
  [Locales.FR]: "https://www.polestar.com/fr-be/test-drive/"
}

const NewCarSuccess: React.FC = () => {
  const { t } = useTranslate()
  const locale = useSelector(getAppLocale)

  return (
    <>
      <Space bottom={5}>
        <Heading>{t("page.completed.heading")}</Heading>
        <p>{t("page.preowned.completed.body")}</p>
      </Space>

      <StyledBottomBox>
        <Heading as="h2">{t("page.completed.cta.heading")}</Heading>
        <p>{t("page.completed.cta.body")}</p>
        <Button component={<a href={href[locale]} target="_blank" />}>
          {t("page.completed.cta.button.label")}
        </Button>
      </StyledBottomBox>
    </>
  )
}

export default NewCarSuccess